import * as React from 'react'
import Header from '../components/Header/Header'
import SignUpForm from '../components/layoutComponents/signUp/SignUp'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import { Helmet } from 'react-helmet'

const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Sign Up | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>Sign Up</h1>
        <p>THINGS TO DO. ARTS. EAT. TRAVEL. FASHION. BEAUTY. KIDS</p>
        <p>
          Sign up for a free account in seconds and be the first to know about
          brilliant things happening near you. Plus access our luxe Reader
          Treats, and save your favourite recommendations for later
        </p>
      </Intro>
      <SignUpForm />
      <Footer />
    </>
  )
}

export default SignUp
