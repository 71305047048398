import React, {
  useState,
  useReducer,
  ChangeEvent,
  useEffect,
  useContext
} from 'react'
import { Link, navigate } from 'gatsby'
import * as SignUpStyles from './SignUp.module.scss'
import { MULTISITE_COUNTIES } from '../../../lib/data/Sites'
import { signUp } from '../../../api/signup'
import { LoginContext } from '../../../lib/utils'
import LogInMutation, {
  type ILogInMutationData,
  type ILogInMutationVariables
} from '../../../graphql/queries/LogInMutation'
import { useMutation } from '@apollo/client'
import DebrettsCTA from '../../../components/BestSchoolsAwards/DebrettsCTA/DebrettsCTA'

const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

interface Props {
  redirect?: boolean
  schoolAwards?: boolean
}

export const SignUpForm = ({ redirect = true, schoolAwards }: Props) => {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const params = new URLSearchParams(
    typeof window !== 'undefined' ? location.search : undefined
  )
  const [error, setError] = useState<string | null>(null)
  const { usr, setUsr } = useContext(LoginContext)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [formValues, setFormValues] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    []
  )
  const formFields = ['name', 'emailaddress', 'password', 'passwordcheck']
  const [logIn] = useMutation<ILogInMutationData, ILogInMutationVariables>(
    LogInMutation
  )

  const logNewUserIn = (username: string, pw: string) => {
    logIn({
      variables: {
        username: username,
        password: pw
      }
    })
      .then(response => {
        const authToken = response.data?.login.authToken
        const usrDetails = {
          ...response.data?.login.user,
          saves: response.data?.getSaves.map(i => i.id)
        }
        if (authToken) {
          localStorage.setItem('token', authToken)
          localStorage.setItem('musr', JSON.stringify(usrDetails))
          usrDetails && setUsr && setUsr({ viewer: usrDetails })
        }
      })
      .catch(e => setError(e))
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false)
    setError(null)
    const { name, checked } = event.target
    let { value } = event.target
    if (name === 'locations' && formValues.locations?.length > 0) {
      value = checked
        ? [...formValues.locations, value]
        : [...formValues.locations].filter(i => i !== value)
      value = [...new Set(value)]
    } else if (name === 'locations') {
      value = [value]
    }

    setFormValues({ [name]: value })
  }

  const handleFormSubmission = () => {
    setSubmitting(true)
    setError(null)
    signUp({ data: formValues })
      .then(response => {
        if (response === 'Success') {
          setSubmitted(true)
          logNewUserIn(formValues.name, formValues.password)
        } else {
          if (response.code) {
            setError(`Error: ${response.message}`)
          } else {
            setError(response)
          }
        }
      })
      .catch(() => {
        setError(
          'Sorry, there was an error creating your account. Could you try again?'
        )
      })
      .finally(() => setSubmitting(false))
  }

  const isValid = () => {
    const isEmailValid = formValues.email
      ? emailIsValid(formValues.email)
      : true
    const isPasswordMatch =
      formValues.passwordcheck &&
      formValues.passwordcheck === formValues.password
    const isSchoolAwardsValid = schoolAwards
      ? 'debretts' in formValues
        ? true
        : false
      : true
    const allFieldsFilled = formFields.every(
      required => formValues[required] && formValues[required].length > 0
    )

    return (
      isEmailValid && isPasswordMatch && isSchoolAwardsValid && allFieldsFilled
    )
  }

  useEffect(() => {
    if (redirect) {
      if (usr?.viewer?.name) {
        navigate('/dashboard/')
      }
    }
  }, [usr])

  useEffect(() => {
    if (params?.get('email')) {
      setFormValues({
        emailaddress: params.get('email')
      })
    }
  }, [])

  return (
    <div className={SignUpStyles.Wrapper}>
      <form className={SignUpStyles.Form}>
        <p className={submitting && !formValues.name ? SignUpStyles.Error : ''}>
          <label htmlFor="name">Your username</label>
          <input
            type={`text`}
            id="name"
            name="name"
            value={formValues.name || ''}
            onChange={handleInputChange}
          />
        </p>
        <p>
          <label htmlFor="emailaddress">Email address</label>
          <input
            type={`email`}
            id="emailaddress"
            name="emailaddress"
            value={formValues.emailaddress || ''}
            onChange={handleInputChange}
          />
        </p>
        <p>
          <label htmlFor="password">Password</label>
          <input
            type={`password`}
            id="password"
            name="password"
            value={formValues.password || ''}
            onChange={handleInputChange}
          />
        </p>
        <p
          className={
            formValues.passwordcheck &&
            formValues.passwordcheck !== formValues.password
              ? SignUpStyles.Error
              : ''
          }
        >
          <label htmlFor="passwordcheck">Repeat your password</label>
          <span className={SignUpStyles.FormHint}>
            Ensure your passwords match
          </span>
          <input
            type={`password`}
            id="passwordcheck"
            name="passwordcheck"
            value={formValues.passwordcheck || ''}
            onChange={handleInputChange}
          />
        </p>
        {schoolAwards && (
          <DebrettsCTA
            setFormValues={setFormValues}
            email={formValues.emailaddress || ''}
            submit={submitted}
          />
        )}
        <p>
          <label htmlFor="passwordcheck">
            Choose which county you’d like to receive newsletters from. (Local
            to you? Where you like to holiday?) You can select as many as you
            like and unsubscribe at any time.
          </label>
          <span className={SignUpStyles.CountySelectorWrap}>
            {MULTISITE_COUNTIES.filter(c => c.id !== 6 && c.id !== 16)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(({ id, title }) => (
                <span key={id} className={SignUpStyles.CountySelector}>
                  <input
                    type={`checkbox`}
                    value={id}
                    name={`locations`}
                    onChange={handleInputChange}
                  />
                  {title}
                </span>
              ))}
          </span>
        </p>
        <p className={SignUpStyles.ButtonRow}>
          <button
            type="button"
            onClick={() => handleFormSubmission()}
            disabled={submitted || !isValid()}
          >
            {submitting ? <>Submitting</> : 'Sign Up'}
          </button>
          {error && (
            <span
              className={SignUpStyles.Alert}
              dangerouslySetInnerHTML={{ __html: error || '' }}
            />
          )}
          {submitted && redirect && (
            <span
              className={SignUpStyles.Alert}
            >{`${'Woohoo! You now have a Muddy account. Redirecting to your dashboard...'}`}</span>
          )}
        </p>
        {redirect && (
          <>
            <p className={SignUpStyles.SmallRow}>
              <small>
                Already have a Muddy account? <Link to={`/log-in`}>Log in</Link>
                .
              </small>
            </p>
            <p className={SignUpStyles.SmallRow}>
              Struggling to login? Please contact us at {` `}
              <a href="mailto:hq@muddystilettos.co.uk">
                hq@muddystilettos.co.uk
              </a>
              .
            </p>
          </>
        )}
      </form>
    </div>
  )
}

export default SignUpForm
